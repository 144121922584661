.App{
  margin: 20px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .left{
    width: 20%;
    border: 1px solid skyblue;
  }
  .right{
    width: 80%;
    margin-left: 10px;
  }
}